import { ConsentData } from "../models/app/ConsentData";
import { GoalData } from "../models/app/GoalData";
import { NotificationStatus, PerformanceNotification } from "../models/app/Notification";
import {CanvasStudent} from "../models/canvas/Student";

export const MOCK_STUDENTS: CanvasStudent[] = [
  {"id": 28332183, "userID": "28332183", "sortable_name": "", "name": "Vernon Landes"},
  {"id": 95366984, "userID": "95366984", "sortable_name": "", "name": "Tonette Sakamoto"},
  {"id": 45844627, "userID": "45844627", "sortable_name": "", "name": "Karisa Altom"},
  {"id": 54264654, "userID": "54264654", "sortable_name": "", "name": "Carman Muff"},
  {"id": 46666218, "userID": "46666218", "sortable_name": "", "name": "Leopoldo Spengler"},
  {"id": 41149744, "userID": "41149744", "sortable_name": "", "name": "Gwen Vue"},
  {"id": 35683215, "userID": "35683215", "sortable_name": "", "name": "Felice Estell"},
  {"id": 50326251, "userID": "50326251", "sortable_name": "", "name": "Melissa Hadsell"},
  {"id": 96600883, "userID": "96600883", "sortable_name": "", "name": "Tami Cupples"},
  {"id": 24585559, "userID": "24585559", "sortable_name": "", "name": "Holley Templin"},
  {"id": 16967126, "userID": "16967126", "sortable_name": "", "name": "Barrett Rhymer"},
  {"id": 23744275, "userID": "23744275", "sortable_name": "", "name": "Cayla Payer"},
  {"id": 53036575, "userID": "53036575", "sortable_name": "", "name": "Donna Daughdrill"},
  {"id": 41898388, "userID": "41898388", "sortable_name": "", "name": "Dayna Kubicek"},
  {"id": 59540503, "userID": "59540503", "sortable_name": "", "name": "Analisa Gathright"},
  {"id": 45476233, "userID": "45476233", "sortable_name": "", "name": "Alyson Burkey"},
  {"id": 29376337, "userID": "29376337", "sortable_name": "", "name": "Loretta Habib"},
  {"id": 27034712, "userID": "27034712", "sortable_name": "", "name": "Franklin Lindenberg"},
  {"id": 74114284, "userID": "74114284", "sortable_name": "", "name": "Felipe Gwyn"},
  {"id": 55249485, "userID": "55249485", "sortable_name": "", "name": "Stan Foran"},
  {"id": 43219917, "userID": "43219917", "sortable_name": "", "name": "Dollie Bomba"},
  {"id": 23400528, "userID": "23400528", "sortable_name": "", "name": "Xochitl Sperber"},
  {"id": 95372011, "userID": "95372011", "sortable_name": "", "name": "Quiana Rape"},
  {"id": 46647543, "userID": "46647543", "sortable_name": "", "name": "Adena Spraggins"},
  {"id": 20936679, "userID": "20936679", "sortable_name": "", "name": "Jung Hur"},
  {"id": 70805720, "userID": "70805720", "sortable_name": "", "name": "Lorita Kaye"},
  {"id": 32324131, "userID": "32324131", "sortable_name": "", "name": "Galina Yepez"},
  {"id": 66889858, "userID": "66889858", "sortable_name": "", "name": "Gwenn Dorrell"},
  {"id": 90434281, "userID": "90434281", "sortable_name": "", "name": "Ana Demayo"},
  {"id": 22976781, "userID": "22976781", "sortable_name": "", "name": "Austin Guilbault"},
  {"id": 86899376, "userID": "86899376", "sortable_name": "", "name": "Regenia Elder"},
  {"id": 48470625, "userID": "48470625", "sortable_name": "", "name": "Marceline Buch"},
  {"id": 18298956, "userID": "18298956", "sortable_name": "", "name": "Louetta Curlin"},
  {"id": 74886921, "userID": "74886921", "sortable_name": "", "name": "Debrah Kutz"},
  {"id": 77161172, "userID": "77161172", "sortable_name": "", "name": "Winifred Soto"},
  {"id": 81888190, "userID": "81888190", "sortable_name": "", "name": "Mozell Arteaga"},
  {"id": 31665008, "userID": "31665008", "sortable_name": "", "name": "Donte Phu"},
  {"id": 35251913, "userID": "35251913", "sortable_name": "", "name": "Darleen Birt"},
  {"id": 27817769, "userID": "27817769", "sortable_name": "", "name": "Connie Speegle"},
  {"id": 41393783, "userID": "41393783", "sortable_name": "", "name": "Suzi Roger"},
  {"id": 28624178, "userID": "28624178", "sortable_name": "", "name": "Elroy Saini"},
  {"id": 97222205, "userID": "97222205", "sortable_name": "", "name": "Terrence Zerr"},
  {"id": 21276717, "userID": "21276717", "sortable_name": "", "name": "Norman Heitz"},
  {"id": 81005245, "userID": "81005245", "sortable_name": "", "name": "Robbin Defrancisco"},
  {"id": 38152009, "userID": "38152009", "sortable_name": "", "name": "Paula Uhrig"},
  {"id": 76262947, "userID": "76262947", "sortable_name": "", "name": "Chrissy Pan"},
  {"id": 20380320, "userID": "20380320", "sortable_name": "", "name": "Cris Loken"},
  {"id": 48905367, "userID": "48905367", "sortable_name": "", "name": "Tad Bank"},
  {"id": 42345728, "userID": "42345728", "sortable_name": "", "name": "Bree Rivers"},
  {"id": 96955357, "userID": "96955357", "sortable_name": "", "name": "Edythe Marrone"},
  {"id": 43754947, "userID": "43754947", "sortable_name": "", "name": "Judie Lombardi"},
  {"id": 41803722, "userID": "41803722", "sortable_name": "", "name": "Marth Keeling"},
  {"id": 55571292, "userID": "55571292", "sortable_name": "", "name": "Adria Laven"},
  {"id": 52682411, "userID": "52682411", "sortable_name": "", "name": "Su Glickman"}
]

export const MOCK_NOTIFICATIONS: PerformanceNotification[] = [
  {"userID": "28332183", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "28332183", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "28332183", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "95366984", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "95366984", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "95366984", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "45844627", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "45844627", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "45844627", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "54264654", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "54264654", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "54264654", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "46666218", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "46666218", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "46666218", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "41149744", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "41149744", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "41149744", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "35683215", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "35683215", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "35683215", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "50326251", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "50326251", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "50326251", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "96600883", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "96600883", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "96600883", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "24585559", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "24585559", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "24585559", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "16967126", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "16967126", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "16967126", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "23744275", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "23744275", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "23744275", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "53036575", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "53036575", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "53036575", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "41898388", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "41898388", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "41898388", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "59540503", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "59540503", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "59540503", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "45476233", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "45476233", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "45476233", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "29376337", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "29376337", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "29376337", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "27034712", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "27034712", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "27034712", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "74114284", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "74114284", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "74114284", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "55249485", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "55249485", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "55249485", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "43219917", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "43219917", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "43219917", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "23400528", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "23400528", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "23400528", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "95372011", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "95372011", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "95372011", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "46647543", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "46647543", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "46647543", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "20936679", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "20936679", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "20936679", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "70805720", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "70805720", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "70805720", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "32324131", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "32324131", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "32324131", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "66889858", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "66889858", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "66889858", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "90434281", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "90434281", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "90434281", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "22976781", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "22976781", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "22976781", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "86899376", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "86899376", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "86899376", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "48470625", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "48470625", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "48470625", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "18298956", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "18298956", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "18298956", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "74886921", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "74886921", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "74886921", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "77161172", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "77161172", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "77161172", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "81888190", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "81888190", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "81888190", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "31665008", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "31665008", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "31665008", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "35251913", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "35251913", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "35251913", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "27817769", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "27817769", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "27817769", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "41393783", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "41393783", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "41393783", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "28624178", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "28624178", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "28624178", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "97222205", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "97222205", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "97222205", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "21276717", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "21276717", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "21276717", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "81005245", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "81005245", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "81005245", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "38152009", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "38152009", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "38152009", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "76262947", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "76262947", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "76262947", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "20380320", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "20380320", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "20380320", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "48905367", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "48905367", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "48905367", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "42345728", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "42345728", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "42345728", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "96955357", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "96955357", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "96955357", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "43754947", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "43754947", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "43754947", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "41803722", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "41803722", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "41803722", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "55571292", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "55571292", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "55571292", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
  {"userID": "52682411", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": true},
  {"userID": "52682411", "tile_id": 2, "status": NotificationStatus.outperforming, "sent": false},
  {"userID": "52682411", "tile_id": 4, "status": NotificationStatus.closing_gap, "sent": false},
]

export const MOCK_CONSENTS: ConsentData[] = [
  {"courseID": 994, "userID": "28332183", "granted": 1, "userName": "Vernon Landes"},
  {"courseID": 994, "userID": "23400528", "granted": 0, "userName": "Xochitl Sperber"},
  {"courseID": 994, "userID": "95366984", "granted": 1, "userName": "Tonette Sakamoto"},
  {"courseID": 994, "userID": "45844627", "granted": 1, "userName": "Karisa Altom"},
  {"courseID": 994, "userID": "54264654", "granted": -1, "userName": "Carman Muff"},
  {"courseID": 994, "userID": "70805720", "granted": 0, "userName": "Lorita Kaye"},
  {"courseID": 994, "userID": "46666218", "granted": 1, "userName": "Leopoldo Spengler"},
  {"courseID": 994, "userID": "41149744", "granted": 1, "userName": "Gwen Vue"},
  {"courseID": 994, "userID": "35683215", "granted": 1, "userName": "Felice Estell"},
  {"courseID": 994, "userID": "22976781", "granted": 0, "userName": "Austin Guilbault"},
  {"courseID": 994, "userID": "50326251", "granted": 1, "userName": "Melissa Hadsell"},
  {"courseID": 994, "userID": "48470625", "granted": 0, "userName": "Marceline Buch"},
  {"courseID": 994, "userID": "96600883", "granted": 1, "userName": "Tami Cupples"},
  {"courseID": 994, "userID": "24585559", "granted": 1, "userName": "Holley Templin"},
  {"courseID": 994, "userID": "77161172", "granted": 0, "userName": "Winifred Soto"},
  {"courseID": 994, "userID": "16967126", "granted": 1, "userName": "Barrett Rhymer"},
  {"courseID": 994, "userID": "31665008", "granted": 0, "userName": "Donte Phu"},
  {"courseID": 994, "userID": "23744275", "granted": 1, "userName": "Cayla Payer"},
  {"courseID": 994, "userID": "27817769", "granted": 0, "userName": "Connie Speegle"},
  {"courseID": 994, "userID": "53036575", "granted": 1, "userName": "Donna Daughdrill"},
  {"courseID": 994, "userID": "28624178", "granted": 0, "userName": "Elroy Saini"},
  {"courseID": 994, "userID": "41898388", "granted": 1, "userName": "Dayna Kubicek"},
  {"courseID": 994, "userID": "59540503", "granted": 1, "userName": "Analisa Gathright"},
  {"courseID": 994, "userID": "45476233", "granted": 1, "userName": "Alyson Burkey"},
  {"courseID": 994, "userID": "29376337", "granted": 1, "userName": "Loretta Habib"},
  {"courseID": 994, "userID": "76262947", "granted": 0, "userName": "Chrissy Pan"},
  {"courseID": 994, "userID": "27034712", "granted": 1, "userName": "Franklin Lindenberg"},
  {"courseID": 994, "userID": "74114284", "granted": 1, "userName": "Felipe Gwyn"},
  {"courseID": 994, "userID": "55249485", "granted": 1, "userName": "Stan Foran"},
  {"courseID": 994, "userID": "43219917", "granted": 1, "userName": "Dollie Bomba"},
  {"courseID": 994, "userID": "43754947", "granted": 0, "userName": "Judie Lombardi"},
  {"courseID": 994, "userID": "95372011", "granted": 0, "userName": "Quiana Rape"},
  {"courseID": 994, "userID": "46647543", "granted": 0, "userName": "Adena Spraggins"},
  {"courseID": 994, "userID": "20936679", "granted": 1, "userName": "Jung Hur"},
  {"courseID": 994, "userID": "32324131", "granted": 0, "userName": "Galina Yepez"},
  {"courseID": 994, "userID": "66889858", "granted": 0, "userName": "Gwenn Dorrell"},
  {"courseID": 994, "userID": "90434281", "granted": 1, "userName": "Ana Demayo"},
  {"courseID": 994, "userID": "86899376", "granted": 0, "userName": "Regenia Elder"},
  {"courseID": 994, "userID": "18298956", "granted": 1, "userName": "Louetta Curlin"},
  {"courseID": 994, "userID": "74886921", "granted": 0, "userName": "Debrah Kutz"},
  {"courseID": 994, "userID": "81888190", "granted": 0, "userName": "Mozell Arteaga"},
  {"courseID": 994, "userID": "35251913", "granted": 1, "userName": "Darleen Birt"},
  {"courseID": 994, "userID": "41393783", "granted": 1, "userName": "Suzi Roger"},
  {"courseID": 994, "userID": "97222205", "granted": 1, "userName": "Terrence Zerr"},
  {"courseID": 994, "userID": "21276717", "granted": 0, "userName": "Norman Heitz"},
  {"courseID": 994, "userID": "81005245", "granted": 0, "userName": "Robbin Defrancisco"},
  {"courseID": 994, "userID": "38152009", "granted": 0, "userName": "Paula Uhrig"},
  {"courseID": 994, "userID": "20380320", "granted": 1, "userName": "Cris Loken"},
  {"courseID": 994, "userID": "48905367", "granted": 0, "userName": "Tad Bank"},
  {"courseID": 994, "userID": "42345728", "granted": 1, "userName": "Bree Rivers"},
  {"courseID": 994, "userID": "96955357", "granted": 1, "userName": "Edythe Marrone"},
  {"courseID": 994, "userID": "41803722", "granted": 0, "userName": "Marth Keeling"},
  {"courseID": 994, "userID": "55571292", "granted": 0, "userName": "Adria Laven"},
  {"courseID": 994, "userID": "52682411", "granted": 0, "userName": "Su Glickman"}
]

export const MOCK_GOALS: GoalData[] = [
  {"courseID": 994, "userID": "28332183", "grade": 1},
  {"courseID": 994, "userID": "23400528", "grade": 10},
  {"courseID": 994, "userID": "95366984", "grade": 10},
  {"courseID": 994, "userID": "45844627", "grade": 8},
  {"courseID": 994, "userID": "54264654", "grade": 9},
  {"courseID": 994, "userID": "70805720", "grade": 7},
  {"courseID": 994, "userID": "46666218", "grade": 5},
  {"courseID": 994, "userID": "41149744", "grade": 4},
  {"courseID": 994, "userID": "35683215", "grade": 9},
  {"courseID": 994, "userID": "22976781", "grade": 8},
  {"courseID": 994, "userID": "50326251", "grade": 7},
  {"courseID": 994, "userID": "48470625", "grade": 8},
  {"courseID": 994, "userID": "96600883", "grade": 9},
  {"courseID": 994, "userID": "24585559", "grade": 7},
  {"courseID": 994, "userID": "77161172", "grade": 6},
  {"courseID": 994, "userID": "16967126", "grade": 6},
  {"courseID": 994, "userID": "31665008", "grade": 6},
  {"courseID": 994, "userID": "23744275", "grade": 6},
  {"courseID": 994, "userID": "27817769", "grade": 5},
  {"courseID": 994, "userID": "53036575", "grade": 9},
  {"courseID": 994, "userID": "28624178", "grade": 8},
  {"courseID": 994, "userID": "41898388", "grade": 7},
  {"courseID": 994, "userID": "59540503", "grade": 5},
  {"courseID": 994, "userID": "45476233", "grade": 9},
  {"courseID": 994, "userID": "29376337", "grade": 7},
  {"courseID": 994, "userID": "76262947", "grade": 9},
  {"courseID": 994, "userID": "27034712", "grade": 10},
  {"courseID": 994, "userID": "74114284", "grade": 10},
  {"courseID": 994, "userID": "55249485", "grade": 6},
  {"courseID": 994, "userID": "43219917", "grade": 6},
  {"courseID": 994, "userID": "43754947", "grade": 10},
  {"courseID": 994, "userID": "95372011", "grade": 5},
  {"courseID": 994, "userID": "46647543", "grade": 4},
  {"courseID": 994, "userID": "20936679", "grade": 8},
  {"courseID": 994, "userID": "32324131", "grade": 9},
  {"courseID": 994, "userID": "66889858", "grade": 8},
  {"courseID": 994, "userID": "90434281", "grade": 3},
  {"courseID": 994, "userID": "86899376", "grade": 6},
  {"courseID": 994, "userID": "18298956", "grade": 8},
  {"courseID": 994, "userID": "74886921", "grade": 5},
  {"courseID": 994, "userID": "81888190", "grade": 6},
  {"courseID": 994, "userID": "35251913", "grade": 9},
  {"courseID": 994, "userID": "41393783", "grade": 8},
  {"courseID": 994, "userID": "97222205", "grade": 7},
  {"courseID": 994, "userID": "21276717", "grade": 5},
  {"courseID": 994, "userID": "81005245", "grade": 6},
  {"courseID": 994, "userID": "38152009", "grade": 9},
  {"courseID": 994, "userID": "20380320", "grade": 5},
  {"courseID": 994, "userID": "48905367", "grade": 7},
  {"courseID": 994, "userID": "42345728", "grade": 9},
  {"courseID": 994, "userID": "96955357", "grade": 7},
  {"courseID": 994, "userID": "41803722", "grade": 6},
  {"courseID": 994, "userID": "55571292", "grade": 7},
  {"courseID": 994, "userID": "52682411", "grade": 0}
]
