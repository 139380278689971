import { TileEntrySubmission } from "../../models/app/Tile";

export const MOCK_QUIZ_SUBMISSIONS: TileEntrySubmission[] = [
  // Quiz 1
  {"entry_id": 1, "grade":"6.89","userID":"55571292"},
  {"entry_id": 1, "grade":"7.17","userID":"28624178"},
  {"entry_id": 1, "grade":"7.89","userID":"55249485"},
  {"entry_id": 1, "grade":"6.44","userID":"46666218"},
  {"entry_id": 1, "grade":"5.89","userID":"45476233"},
  {"entry_id": 1, "grade":"9.0","userID":"77161172"},
  {"entry_id": 1, "grade":"8.11","userID":"42345728"},
  {"entry_id": 1, "grade":"9.0","userID":"41803722"},
  {"entry_id": 1, "grade":"8.75","userID":"76262947"},
  {"entry_id": 1, "grade":"8.44","userID":"48470625"},
  {"entry_id": 1, "grade":"7.33","userID":"59540503"},
  {"entry_id": 1, "grade":"6.14","userID":"24585559"},
  {"entry_id": 1, "grade":"6.0","userID":"96600883"},
  {"entry_id": 1, "grade":"8.5","userID":"27034712"},
  {"entry_id": 1, "grade":"9.0","userID":"16967126"},
  {"entry_id": 1, "grade":"9.0","userID":"16967126"},
  {"entry_id": 1, "grade":"9.0","userID":"53036575"},
  {"entry_id": 1, "grade":"4.0","userID":"31665008"},
  {"entry_id": 1, "grade":"7.83","userID":"20936679"},
  {"entry_id": 1, "grade":"8.0","userID":"70805720"},
  {"entry_id": 1, "grade":"7.33","userID":"41898388"},
  {"entry_id": 1, "grade":"9.25","userID":"22976781"},
  {"entry_id": 1, "grade":"8.25","userID":"54264654"},
  {"entry_id": 1, "grade":"6.89","userID":"46647543"},
  {"entry_id": 1, "grade":"6.67","userID":"86899376"},
  {"entry_id": 1, "grade":"8.89","userID":"27817769"},
  {"entry_id": 1, "grade":"5.78","userID":"35683215"},
  {"entry_id": 1, "grade":"8.33","userID":"18298956"},
  {"entry_id": 1, "grade":"6.78","userID":"38152009"},
  {"entry_id": 1, "grade":"8.81","userID":"50326251"},
  {"entry_id": 1, "grade":"7.11","userID":"28332183"},
  {"entry_id": 1, "grade":"6.44","userID":"23400528"},
  {"entry_id": 1, "grade":"8.28","userID":"81888190"},
  {"entry_id": 1, "grade":"8.06","userID":"74886921"},
  {"entry_id": 1, "grade":"9.78","userID":"32324131"},
  {"entry_id": 1, "grade":"8.56","userID":"66889858"},
  {"entry_id": 1, "grade":"5.83","userID":"90434281"},
  {"entry_id": 1, "grade":"10.0","userID":"23744275"},
  {"entry_id": 1, "grade":"8.33","userID":"43219917"},
  {"entry_id": 1, "grade":"7.67","userID":"41393783"},
  {"entry_id": 1, "grade":"8.11","userID":"74114284"},
  {"entry_id": 1, "grade":"7.36","userID":"96955357"},
  {"entry_id": 1, "grade":"7.33","userID":"52682411"},
  {"entry_id": 1, "grade":"10.0","userID":"29376337"},
  {"entry_id": 1, "grade":"8.22","userID":"45844627"},

  // Quiz 2
  {"entry_id": 2,"grade":"7.14","userID":"55571292"},
  {"entry_id": 2,"grade":"7.96","userID":"28624178"},
  {"entry_id": 2,"grade":"8.26","userID":"55249485"},
  {"entry_id": 2,"grade":"8.84","userID":"46666218"},
  {"entry_id": 2,"grade":"7.43","userID":"45476233"},
  {"entry_id": 2,"grade":"9.05","userID":"77161172"},
  {"entry_id": 2,"grade":"9.42","userID":"42345728"},
  {"entry_id": 2,"grade":"8.02","userID":"41803722"},
  {"entry_id": 2,"grade":"8.25","userID":"76262947"},
  {"entry_id": 2,"grade":"7.32","userID":"48470625"},
  {"entry_id": 2,"grade":"8.65","userID":"59540503"},
  {"entry_id": 2,"grade":"8.65","userID":"24585559"},
  {"entry_id": 2,"grade":"7.31","userID":"96600883"},
  {"entry_id": 2,"grade":"8.29","userID":"27034712"},
  {"entry_id": 2,"grade":"8.65","userID":"16967126"},
  {"entry_id": 2,"grade":"9.02","userID":"16967126"},
  {"entry_id": 2,"grade":"9.02","userID":"53036575"},
  {"entry_id": 2,"grade":"8.47","userID":"20936679"},
  {"entry_id": 2,"grade":"8.47","userID":"70805720"},
  {"entry_id": 2,"grade":"7.85","userID":"41898388"},
  {"entry_id": 2,"grade":"8.15","userID":"22976781"},
  {"entry_id": 2,"grade":"7.84","userID":"54264654"},
  {"entry_id": 2,"grade":"6.13","userID":"86899376"},
  {"entry_id": 2,"grade":"7.35","userID":"35683215"},
  {"entry_id": 2,"grade":"7.81","userID":"18298956"},
  {"entry_id": 2,"grade":"8.65","userID":"38152009"},
  {"entry_id": 2,"grade":"8.52","userID":"50326251"},
  {"entry_id": 2,"grade":"8.28","userID":"28332183"},
  {"entry_id": 2,"grade":"7.54","userID":"23400528"},
  {"entry_id": 2,"grade":"8.65","userID":"81888190"},
  {"entry_id": 2,"grade":"8.82","userID":"74886921"},
  {"entry_id": 2,"grade":"9.02","userID":"32324131"},
  {"entry_id": 2,"grade":"6.86","userID":"66889858"},
  {"entry_id": 2,"grade":"7.1","userID":"90434281"},
  {"entry_id": 2,"grade":"9.82","userID":"23744275"},
  {"entry_id": 2,"grade":"7.61","userID":"43219917"},
  {"entry_id": 2,"grade":"8.19","userID":"41393783"},
  {"entry_id": 2,"grade":"9.24","userID":"74114284"},
  {"entry_id": 2,"grade":"7.58","userID":"52682411"},
  {"entry_id": 2,"grade":"7.8","userID":"29376337"},
  {"entry_id": 2,"grade":"9.82","userID":"45844627"},

  // Quiz 3
  {"entry_id": 3,"grade":"6.71","userID":"55571292"},
  {"entry_id": 3,"grade":"6.71","userID":"55249485"},
  {"entry_id": 3,"grade":"6.71","userID":"46666218"},
  {"entry_id": 3,"grade":"4.5","userID":"45476233"},
  {"entry_id": 3,"grade":"9.0","userID":"77161172"},
  {"entry_id": 3,"grade":"9.5","userID":"42345728"},
  {"entry_id": 3,"grade":"9.0","userID":"41803722"},
  {"entry_id": 3,"grade":"7.0","userID":"76262947"},
  {"entry_id": 3,"grade":"9.5","userID":"48470625"},
  {"entry_id": 3,"grade":"5.86","userID":"59540503"},
  {"entry_id": 3,"grade":"7.0","userID":"24585559"},
  {"entry_id": 3,"grade":"8.43","userID":"96600883"},
  {"entry_id": 3,"grade":"6.0","userID":"27034712"},
  {"entry_id": 3,"grade":"4.14","userID":"16967126"},
  {"entry_id": 3,"grade":"8.0","userID":"16967126"},
  {"entry_id": 3,"grade":"8.0","userID":"53036575"},
  {"entry_id": 3,"grade":"6.71","userID":"20936679"},
  {"entry_id": 3,"grade":"10.0","userID":"70805720"},
  {"entry_id": 3,"grade":"10.0","userID":"41898388"},
  {"entry_id": 3,"grade":"6.14","userID":"22976781"},
  {"entry_id": 3,"grade":"9.5","userID":"54264654"},
  {"entry_id": 3,"grade":"4.14","userID":"86899376"},
  {"entry_id": 3,"grade":"5.71","userID":"35683215"},
  {"entry_id": 3,"grade":"10.0","userID":"18298956"},
  {"entry_id": 3,"grade":"7.43","userID":"38152009"},
  {"entry_id": 3,"grade":"8.86","userID":"50326251"},
  {"entry_id": 3,"grade":"6.43","userID":"35251913"},
  {"entry_id": 3,"grade":"9.71","userID":"28332183"},
  {"entry_id": 3,"grade":"4.0","userID":"23400528"},
  {"entry_id": 3,"grade":"6.71","userID":"81888190"},
  {"entry_id": 3,"grade":"10.0","userID":"74886921"},
  {"entry_id": 3,"grade":"10.0","userID":"32324131"},
  {"entry_id": 3,"grade":"4.29","userID":"90434281"},
  {"entry_id": 3,"grade":"10.0","userID":"23744275"},
  {"entry_id": 3,"grade":"6.71","userID":"97222205"},
  {"entry_id": 3,"grade":"6.0","userID":"43219917"},
  {"entry_id": 3,"grade":"6.0","userID":"41393783"},
  {"entry_id": 3,"grade":"10.0","userID":"74114284"},
  {"entry_id": 3,"grade":"7.71","userID":"96955357"},
  {"entry_id": 3,"grade":"8.71","userID":"52682411"},
  {"entry_id": 3,"grade":"4.71","userID":"29376337"},
  {"entry_id": 3,"grade":"10.0","userID":"45844627"},

  // Quiz 4
  {"entry_id": 4,"grade":"4.28","userID":"55571292"},
  {"entry_id": 4,"grade":"5.96","userID":"55249485"},
  {"entry_id": 4,"grade":"4.07","userID":"46666218"},
  {"entry_id": 4,"grade":"3.16","userID":"45476233"},
  {"entry_id": 4,"grade":"8.6","userID":"77161172"},
  {"entry_id": 4,"grade":"8.19","userID":"42345728"},
  {"entry_id": 4,"grade":"7.54","userID":"41803722"},
  {"entry_id": 4,"grade":"5.68","userID":"76262947"},
  {"entry_id": 4,"grade":"6.99","userID":"48470625"},
  {"entry_id": 4,"grade":"3.95","userID":"59540503"},
  {"entry_id": 4,"grade":"4.74","userID":"24585559"},
  {"entry_id": 4,"grade":"6.78","userID":"96600883"},
  {"entry_id": 4,"grade":"6.78","userID":"27034712"},
  {"entry_id": 4,"grade":"7.47","userID":"16967126"},
  {"entry_id": 4,"grade":"7.61","userID":"16967126"},
  {"entry_id": 4,"grade":"7.61","userID":"53036575"},
  {"entry_id": 4,"grade":"6.68","userID":"70805720"},
  {"entry_id": 4,"grade":"6.48","userID":"41898388"},
  {"entry_id": 4,"grade":"6.08","userID":"22976781"},
  {"entry_id": 4,"grade":"6.27","userID":"86899376"},
  {"entry_id": 4,"grade":"5.07","userID":"35683215"},
  {"entry_id": 4,"grade":"6.11","userID":"18298956"},
  {"entry_id": 4,"grade":"5.33","userID":"38152009"},
  {"entry_id": 4,"grade":"7.74","userID":"50326251"},
  {"entry_id": 4,"grade":"4.72","userID":"35251913"},
  {"entry_id": 4,"grade":"5.77","userID":"28332183"},
  {"entry_id": 4,"grade":"4.86","userID":"23400528"},
  {"entry_id": 4,"grade":"7.43","userID":"81888190"},
  {"entry_id": 4,"grade":"5.09","userID":"74886921"},
  {"entry_id": 4,"grade":"4.93","userID":"32324131"},
  {"entry_id": 4,"grade":"4.45","userID":"90434281"},
  {"entry_id": 4,"grade":"9.18","userID":"23744275"},
  {"entry_id": 4,"grade":"4.66","userID":"43219917"},
  {"entry_id": 4,"grade":"5.63","userID":"41393783"},
  {"entry_id": 4,"grade":"6.24","userID":"74114284"},
  {"entry_id": 4,"grade":"4.44","userID":"52682411"},
  {"entry_id": 4,"grade":"4.59","userID":"29376337"},

  // Quiz 6
  {"entry_id": 6,"grade":"4.28","userID":"55571292"},
  {"entry_id": 6,"grade":"5.96","userID":"55249485"}
]
