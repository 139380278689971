import { HistoricTileGrades } from "../components/StudentDashboard/TileHistoricGraph/types";

export const MOCK_GRADE_HISTORY: Map<number, HistoricTileGrades> = new Map([
    [1,
    {
        dates: ["4/18/2023 11:30:50 PM","4/19/2023 4:38:55 PM","4/19/2023 5:09:16 PM","4/20/2023 7:02:43 PM","4/20/2023 11:22:03 PM","4/21/2023 9:42:17 AM", "4/22/2023 10:00:01 AM"],
        user_avg: [6.1,5.54,7.2,8,6,7.3,7.8],
        peer_avg: [5.5,6.3,7,8,6.66,5.5,5],
        peer_max: [8.1,9.67,8,9.99,7.34,8.5,9],
        peer_min: [3.02,1.11,4.2,5,3.5,4.01,3.33]
    }],
    [2,
    {
        dates: ["4/18/2023 11:30:50 PM","4/19/2023 4:38:55 PM","4/19/2023 5:09:16 PM","4/20/2023 7:02:43 PM","4/20/2023 11:22:03 PM","4/21/2023 9:42:17 AM", "4/22/2023 10:00:01 AM"],
        user_avg: [6.1, 5.54, 7.2, 8, 6, 7.3, 7.8],
        peer_avg: [5.5, 6.3, 7, 8, 6.66, 5.5, 5],
        peer_max: [8.1, 9.67, 8, 9.99, 7.34, 8.5, 9],
        peer_min: [3.02, 1.11, 4.2, 5, 3.5, 4.01, 3.33]

    }],
    // [3,
    // {
    //     dates: ["5/7/2023 11:30:50 PM","5/8/2023 4:38:55 PM","5/8/2023 5:09:16 PM","5/9/2023 7:02:43 PM","5/9/2023 11:22:03 PM","5/10/2023 9:42:17 AM", "5/11/2023 10:00:01 AM"],
    //     user_avg: [7.9, 8.54, 7.2, 8, 6, 8.3, 7.8],
    //     peer_avg: [6.5, 5.3, 7, 8.5, 6.66, 5.5, 6.4],
    //     peer_max: [8.2, 9.1, 8, 9.99, 7.34, 8.5, 7.5],
    //     peer_min: [2.5, 1.11, 4.2, 5, 3.5, 4.01, 3.33]

    // }],
    [4,
    {
        dates: ["5/12/2023 11:30:50 PM","5/13/2023 4:38:55 PM","5/13/2023 5:09:16 PM","5/14/2023 7:02:43 PM","5/14/2023 11:22:03 PM","5/15/2023 9:42:17 AM", "5/16/2023 10:00:01 AM"],
        user_avg: [8.1, 5.54, 7.8, 8, 6.5, 7.5, 8.8],
        peer_avg: [6.1, 6.8, 7.2, 8, 6.66, 5.5, 6.2],
        peer_max: [8.3, 9.67, 8, 9.99, 7.34, 8.5, 7.9],
        peer_min: [3.1, 1.11, 4.2, 5, 3.5, 4.01, 3.33]

    }],
    [5,
    {
        dates: ["5/17/2023 11:30:50 PM","5/18/2023 4:38:55 PM","5/18/2023 5:09:16 PM","5/19/2023 7:02:43 PM","5/19/2023 11:22:03 PM","5/20/2023 9:42:17 AM", "5/21/2023 10:00:01 AM"],
        user_avg: [7.2, 5.9, 7.4, 8, 6.5, 7.1, 7.9],
        peer_avg: [5.9, 6.1, 7.8, 8, 6.66, 5.5, 6.3],
        peer_max: [8.1, 9.67, 8, 9.99, 7.34, 8.5, 7.3],
        peer_min: [3.02, 1.5, 4.2, 5, 3.5, 2.3, 5.3]
        
    }],
    [6,
    {
        dates: ["6/7/2023 11:30:50 PM","6/8/2023 4:38:55 PM","6/8/2023 5:09:16 PM","6/9/2023 7:02:43 PM","6/9/2023 11:22:03 PM","6/10/2023 9:42:17 AM", "6/11/2023 10:00:01 AM"],
        user_avg: [8.2, 5.54, 7.8, 8, 6.5, 7.5, 8.8],
        peer_avg: [6.2, 6.8, 7.2, 8, 6.66, 5.5, 6.2],
        peer_max: [8.4, 9.67, 8, 9.99, 7.34, 8.5, 7.9],
        peer_min: [3.2, 1.11, 4.2, 5, 3.5, 4.01, 3.33]

    }],    
    [7,
    {
        dates: ["6/12/2023 11:30:50 PM","6/13/2023 4:38:55 PM","6/13/2023 5:09:16 PM","6/14/2023 7:02:43 PM","6/14/2023 11:22:03 PM","6/15/2023 9:42:17 AM", "6/16/2023 10:00:01 AM"],
        user_avg: [8.4, 5.54, 7.8, 8, 6.5, 7.5, 8.8],
        peer_avg: [6.4, 6.8, 7.2, 8, 6.66, 5.5, 6.2],
        peer_max: [8.6, 9.67, 8, 9.99, 7.34, 8.5, 7.9],
        peer_min: [3.3, 1.11, 4.2, 5, 3.5, 4.01, 3.33]

    }],
    [8,
    {
        dates: ["6/17/2023 11:30:50 PM","6/18/2023 4:38:55 PM","6/18/2023 5:09:16 PM","6/19/2023 7:02:43 PM","6/19/2023 11:22:03 PM","6/20/2023 9:42:17 AM", "6/21/2023 10:00:01 AM"],
        user_avg: [8.6, 5.54, 7.8, 8, 6.5, 7.5, 8.8],
        peer_avg: [6.6, 6.8, 7.2, 8, 6.66, 5.5, 6.2],
        peer_max: [8.8, 9.67, 8, 9.99, 7.34, 8.5, 7.9],
        peer_min: [3.4, 1.11, 4.2, 5, 3.5, 4.87, 2.5, 5.2]

    }],
    [9,
    {
        dates: ["6/22/2023 11:30:50 PM","6/23/2023 4:38:55 PM","6/23/2023 5:09:16 PM","6/24/2023 7:02:43 PM","6/24/2023 11:22:03 PM","6/25/2023 9:42:17 AM", "6/26/2023 10:00:01 AM"],
        user_avg: [8.8, 5.54, 7.8, 8, 6.5, 7.5, 8.8],
        peer_avg: [6.8, 6.8, 7.2, 8, 6.66, 5.5, 6.2],
        peer_max: [9.0, 9.67, 8, 9.99, 7.34, 8.5, 7.9],
        peer_min: [3.5, 1.11, 4.2, 5, 3.5, 4.01, 3.33]

    }],    

]);
