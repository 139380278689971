import { Tile } from "../../models/app/Tile";

export const MOCK_TILES: Tile[] = [
  {
    id: 1,
    group_id: 1,
    title: "Quizzes",
    position: 1,
    content: "ENTRIES",
    type: "ASSIGNMENTS",
    visible: true,
    wildcard: false,
    graph_view: false,
  },
  {
    id: 2,
    group_id: 1,
    title: "Perusall",
    position: 2,
    content: "ENTRIES",
    type: "EXTERNAL_DATA",
    visible: true,
    wildcard: false,
    graph_view: false,
  },
  {
    id: 3,
    group_id: 1,
    title: "Attendance",
    position: 4,
    content: "BINARY",
    type: "EXTERNAL_DATA",
    visible: true,
    wildcard: false,
    graph_view: false,
  },
  {
    id: 4,
    group_id: 1,
    title: "Practice Sessions",
    position: 3,
    content: "ENTRIES",
    type: "EXTERNAL_DATA",
    visible: true,
    wildcard: false,
    graph_view: false,
  },
  {
    id: 5,
    group_id: 2,
    title: "Exam Grades",
    position: 1,
    content: "ENTRIES",
    type: "ASSIGNMENTS",
    visible: true,
    wildcard: false,
    graph_view: false,
  },
  {
    id: 6,
    group_id: 1,
    title: "Preparation Time",
    position: 5,
    content: "ENTRIES",
    type: "EXTERNAL_DATA",
    visible: true,
    wildcard: false,
    graph_view: true,
  },
  {
    id: 7,
    group_id: 1,
    title: "Send in Questions",
    position: 6,
    content: "ENTRIES",
    type: "DISCUSSIONS",
    visible: true,
    wildcard: true,
    graph_view: false,
  },
  {
    id: 8,
    group_id: 3,
    title: "Predicted Grade",
    position: 1,
    content: "PREDICTION",
    type: "ASSIGNMENTS",
    visible: true,
    wildcard: false,
    graph_view: false,
  },
  {
    id: 9,
    group_id: 3,
    title: "Learning Outcomes",
    position: 2,
    content: "LEARNING_OUTCOMES",
    type: "ASSIGNMENTS",
    visible: true,
    wildcard: true,
    graph_view: false,
  },
];
